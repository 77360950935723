:root {
    --rs-primary-50: #eeffed;
    --rs-primary-100: #c8f0c7;
    --rs-primary-200: #a5e0a4;
    --rs-primary-300: #82cf82;
    --rs-primary-400: #65bf67;
    --rs-primary-500: #4caf50;
    --rs-primary-600: #37ab3c;
    --rs-primary-700: #22a12a;
    --rs-primary-800: #0f9119;
    --rs-primary-900: #008736;
    --rs-bg-active: #008736;
    --rs-btn-link-text: #008736;
    --rs-btn-link-hover-text: #008736;
    --rs-listbox-option-hover-text: #FFFFFF;
    --rs-listbox-option-hover-bg: #274828;
    --rs-btn-primary-hover-bg: #008736;
    --rs-picker-value: #274828;
    --rs-btn-primary-bg: #274828;
    --rs-input-focus-border: #274828;
    --rs-state-focus-shadow: #008736;
    --rs-text-active: #008736;
    --rs-calendar-range-bg: rgba(0, 135, 54, 0.05);
}