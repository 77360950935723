.dynamic-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
}

/* Input container styles */
.form-item {
    margin-bottom: 1rem;
}

/* Label styles */
label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
}

/* Input styles */
.form-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    width: 100%;
    box-sizing: border-box;
}

.status-pending {
    background-color: #dc3545;
    color: white
}

.status-sent_to_bank {
    background-color: #ffaa00;
}
