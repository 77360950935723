@import "~rsuite/dist/rsuite.css";
@import "../src/assets/rsuite.css";

.spinner-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99998;
    opacity: 1;
    background: #ffffff;
    left: 0;
}

.spinner-wrapper > div {
    z-index: 99999;
    position: absolute;
    left: 50%;
    top: 20%;
}

body.modal-open {
    overflow: hidden;
}

.pagination-controls {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.pagination-controls span {
    margin-right: 5px;
}

.pagination-controls input {
    width: 60px;
    margin-right: 5px;
}

.visually-hidden {
    display: none;
}

.navbar-nav .nav-item:hover .nav-link {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
